import * as Sentry from '@sentry/remix';
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { Profiler, startTransition, StrictMode, useEffect, Suspense } from 'react';
import { hydrateRoot } from 'react-dom/client';
import createEmotionCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
// import { cacheAssets } from 'remix-utils/cache-assets';
import i18n from './i18n';
import i18next from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

//TODO https://remix.run/docs/en/main/guides/envvars
// const { SENTRY_ENVIRONMENT, SENTRY_ENABLED } = window.__remixContext.state.loaderData?.root?.ENV;
Sentry.init({
	enabled: SENTRY_ENABLED === 'true' ? true : false,
	environment: SENTRY_ENVIRONMENT,
	dsn: 'https://5693120aae134775c45d13844367d4e2@o4507029099773952.ingest.de.sentry.io/4507033740640336',
	tracesSampleRate: 1.0,
	profilesSampleRate: 0.7,
	// tracePropagationTargets: ['localhost', '127.0.0.1', /^\//],

	// replaysSessionSampleRate: 0.3,
	// replaysOnErrorSampleRate: 1,
	integrations: [
		// Sentry.replayIntegration(),
		Sentry.browserTracingIntegration({
			useEffect,
			useLocation,
			useMatches,
		}),
	],
});

// cacheAssets({ cacheName: 'assets', buildPath: '/build/' }).catch((error) => {
//   // do something with the error, or not
// });

const I18next = async () => {
	// const emotionCache = createEmotionCache({ key: 'css' });
	// const emotionCache = createEmotionCache({ key: 'css', insertionPoint:  });
	const emotionCache = createEmotionCache({ key: 'css', speedy: true });
	await i18next
		.use(initReactI18next) // Tell i18next to use the react-i18next plugin
		.use(LanguageDetector) // Setup a client-side language detector
		.use(Backend) // Setup your backend
		.init({
			...i18n, // spread the configuration
			// This function detects the namespaces your routes rendered while SSR use
			// ns: getInitialNamespaces(),
			// ns: ['common', 'index', 'topbar'],

			backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
			// fallbackLng: 'en',
			detection: {
				// Here only enable htmlTag detection, we'll detect the language only
				// server-side with remix-i18next, by using the `<html lang>` attribute
				// we can communicate to the client the language detected server-side
				order: ['htmlTag'],
				// Because we only use htmlTag, there's no reason to cache the language
				// on the browser, so we disable it
				caches: [],
				// cookie: undefined,
			},
		});
	//* get the lng set in i18next
	// const lng = i18next.language;
	// console.log({ lng });
	startTransition(() => {
		hydrateRoot(
			document,
			<StrictMode>
				<I18nextProvider i18n={i18next}>
					<CacheProvider value={emotionCache}>
						<RemixBrowser />
					</CacheProvider>
				</I18nextProvider>
			</StrictMode>,
		);
	});
};
I18next();

// startTransition(() => {
// 	hydrateRoot(
// 		document,
// 		<StrictMode>
// 			<I18next>
// 				<RemixBrowser />
// 			</I18next>
// 		</StrictMode>,
// 	);
// });

// async function hydrate() {
// 	// const emotionCache = createEmotionCache({ key: 'css' });
// 	// const emotionCache = createEmotionCache({ key: 'css', insertionPoint:  });
// 	const emotionCache = createEmotionCache({ key: 'css', speedy: true });
// 	await i18next
// 		.use(initReactI18next) // Tell i18next to use the react-i18next plugin
// 		.use(LanguageDetector) // Setup a client-side language detector
// 		.use(Backend) // Setup your backend
// 		.init({
// 			...i18n, // spread the configuration
// 			// This function detects the namespaces your routes rendered while SSR use
// 			// ns: getInitialNamespaces(),
// 			// ns: ['common', 'index', 'topbar'],

// 			backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
// 			// fallbackLng: 'en',
// 			detection: {
// 				// Here only enable htmlTag detection, we'll detect the language only
// 				// server-side with remix-i18next, by using the `<html lang>` attribute
// 				// we can communicate to the client the language detected server-side
// 				order: ['htmlTag'],
// 				// Because we only use htmlTag, there's no reason to cache the language
// 				// on the browser, so we disable it
// 				caches: [],
// 				// cookie: undefined,
// 			},
// 		});
// 	//* get the lng set in i18next
// 	// const lng = i18next.language;
// 	// console.log({ lng });
// 	startTransition(() => {
// 		hydrateRoot(
// 			document,
// 			// <Profiler
// 			// 	id='App'
// 			// 	onRender={(id, phase, actualDuration) => {
// 			// 		console.log({ id, phase, actualDuration });
// 			// 	}}
// 			// >
// 			<I18nextProvider i18n={i18next}>
// 				<StrictMode>
// 					<CacheProvider value={emotionCache}>
// 						<RemixBrowser />
// 					</CacheProvider>
// 				</StrictMode>
// 			</I18nextProvider>,
// 			// </Profiler>,
// 		);
// 	});
// }

// if (typeof requestIdleCallback === 'function') {
// 	requestIdleCallback(hydrate);
// } else {
// 	// Safari doesn't support requestIdleCallback
// 	// https://caniuse.com/requestidlecallback
// 	setTimeout(hydrate, 1);
// }
